<template>
    <b-row class="justify-content-center mx-0">
        <b-col cols="11">
            <!--filter-->
            <b-row class="mt-3" cols-xl="3" cols-md="2" cols="1">
                <b-col>
                    <b-form-group label="Country">
                        <p v-if="invalid_country" class="validation_error_message">Please select at least one country</p>
                        <v-select :options="countries_list" label="country" v-model="country" placeholder="Select country"
                            :closeOnSelect="false" class="form-control-filter" :multiple="true" :clearable="true"
                            :reduce="item => item.country"></v-select>

                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Industry">
                        <p v-if="invalid_industry" class="validation_error_message">Please select at least one industry</p>
                        <v-select :options="industry_list" label="name" v-model="industry" placeholder="Select industry"
                            :closeOnSelect="false" class="form-control-filter" :multiple="true" :clearable="true"
                            :reduce="item => item.name"></v-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Violation Type">
                        <p v-if="invalid_violation_type" class="validation_error_message">Please select at least one type
                        </p>
                        <v-select :options="violation_type_list" label="name" v-model="violation_type"
                            placeholder="Select type" :closeOnSelect="false" class="form-control-filter" :multiple="true"
                            :clearable="true" :reduce="item => item.name"></v-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="From">
                        <p v-if="invalid_date_from" class="validation_error_message">{{ invalid_date_from_msg }}</p>
                        <b-form-datepicker v-model="date_from" type="month" button-variant="warning"
                            nav-button-variant="secondary" :max="new Date()"></b-form-datepicker>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="To">
                        <p v-if="invalid_date_to" class="validation_error_message">{{ invalid_date_to_msg }}</p>
                        <b-form-datepicker v-model="date_to" button-variant="warning" nav-button-variant="secondary"
                            :max="new Date()"></b-form-datepicker>
                    </b-form-group>
                </b-col>
                <b-col class="d-flex align-items-center">
                    <b-button variant="primary-protostars" class="btn-filter w-50" @click="clickFilter">Filter</b-button>
                </b-col>

            </b-row>
            <!-- {{ country }} , {{ industry }}, {{ violation_type }}, {{ date_from }}, {{ date_to }} -->
            <b-overlay :show="show_overlay" rounded="sm">
                <b-row class="mt-2">
                    <b-col>
                        <!-- <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts> -->
                        <b-card no-body class="my-1">
                            <b-card-body>
                                <BarDrilldownChart ref="barChartComponent" :title="'Sum Of Fines By Year And Country'"
                                    :subtitle="'Click the columns to view countries.'" :yAxis_title="'Sum of fines'"
                                    :series_name="'Years'" :colors="colors" :series_data="total_fine_data"
                                    :drilldown_data="total_fine_drilldown_series" v-model:is_drilldown="is_drilldown" />
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row class="mt-2 mb-5" cols="1">
                    <b-col>
                        <b-card no-body class="my-1">
                            <b-card-body>
                                <PieChart :title="'Sum Of Fines By Violation Type'" :series_name="'Violation Types'"
                                    :series_data="pie_chart_data_violation_type" :colors="colors_pie_chart" />
                                <!-- <highcharts class="hc" :options="pieChartOptions" ref="piechart"></highcharts> -->

                            </b-card-body>

                        </b-card>
                    </b-col>
                </b-row>
                <b-row class="mt-2 mb-5" cols="1">
                    <b-col>
                        <b-card no-body class="my-1">
                            <b-card-body>
                                <PieChart :title="'Sum Of Fines By Industry'" :series_name="'Industries'"
                                    :series_data="pie_chart_data_industry" :colors="colors_pie_chart" />
                                <!-- <highcharts class="hc" :options="pieChartOptions" ref="piechart"></highcharts> -->
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-overlay>
        </b-col>
    </b-row>
</template>


<script >

import moment from "moment";
import axios from 'axios';

import europe_countries from '@/assets/country-by-continent.json'

import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import drilldown from "highcharts/modules/drilldown";
import stockInit from "highcharts/modules/stock";

exportingInit(Highcharts)
drilldown(Highcharts)
stockInit(Highcharts)

import PieChart from '@/components/dashboard/PieChart.vue'
import BarDrilldownChart from '@/components/dashboard/BarDrilldownChart.vue'

// const colorsR = Highcharts.getOptions().colors.map((c, i) =>
//     // Start out with a darkened base color (negative brighten), and end
//     // up with a much brighter color
//     Highcharts.color(Highcharts.getOptions().colors[3])
//         .brighten((i - 1) / 10)
//         .get()
// );
// console.log(colorsR)

export default {
    name: 'ProtostarsStatisticsView',
    components: {
        PieChart, BarDrilldownChart
    },
    data() {
        let temp = this;
        return {
            colors: ['#696682'],
            colors_pie_chart:
                // ["#E68D56","#e6d556","#77e656", "#5699e6","#6447e8", "#c456e6", "#e65677"
                // ],
                // ["#","#", "#", "#", "#", "#e2e2e2", "#e1a692", "#","#", "#", "#c23728"],
                ['#696682', "#8b6d96", "#b56f9c", "#df7295", "#ff7a80", "#ff8d61", "#ffaa3d", "#ffcc05", "#c2e54e", "#88f391", "#59fad0", "#61fbfe"],
            // ["#E68D56","#B06C41", "#ffe20a", "#02e815",
            //  "#0AB9FF", "#720AF5", "#4E5FF2",
            //  "#fce851", "#61f26e","#430ee3",
            //  "#A80100", "#00A82A", "#007BA8"],

            // [
            //     "#e68d56", // (main color)
            //     "#f4a261", //  (lighter orange)
            //     "#d96a38", //  (darker orange)
            //     "#c02942", //  (red)
            //     "#b71c1c", //  (maroon)
            //     "#ffe18b", //  (yellow)
            //     "#d9d919", //  (lime green)
            //     "#53777a", //  (teal)
            //     "#8599a8", //  (blue)
            //     "#667c8b", //  (purple)
            //     "#4f5b66", //  (brown)
            //     "#d9d9d9", //  (grey)
            // ],
            // [
            //     "#d8723b",// (terracotta)
            //     "#c75d29",// (rust)
            //     "#b44b17",// (sienna)
            //     "#ffbc71",// (mango)
            //     "#f5a94e",// (cantaloupe)
            //     "#e99433",// (peach)
            //     "#d37724",// (apricot)
            //     "#c25f1f",// (coral)
            //     "#b0481a",// (salmon)
            //     "#9e3715",// (orange)
            //     "#8c2610",// (red)
            //     "#7a150b",// (scarlet)
            // ],
            // ['#e68d56', '#e89664', '#eba57a',
            //     '#eeb490', '#f2c3a6', '#f5d2bc',
            //     '#9b4a17', '#b1541b', '#c85f1e',
            //     '#de6921', '#e17837', '#e4874e',
            // ],
            // filter data
            country: null,
            invalid_country: false,
            countries_list: [],
            industry: null,
            invalid_industry: false,
            industry_list: [],
            violation_type: null,
            invalid_violation_type: false,
            violation_type_list: [],
            date_from: null,
            date_from_str: null,
            invalid_date_from: false,
            invalid_date_from_msg: "",
            date_to: null,
            date_to_str: null,
            invalid_date_to: false,
            invalid_date_to_msg: "",

            show_overlay: false,
            is_drilldown: false,
            total_fine_data: [],
            total_fine_drilldown_series: [],
            // [
            //     {
            //         name: '2020',
            //         y: 400,
            //         drilldown: '2020'
            //     },
            //     {
            //         name: '2021',
            //         y: 50,
            //         drilldown: '2021'
            //     },
            //     {
            //         name: '2022',
            //         y: 80,
            //         drilldown: '2022'
            //     },
            //     {
            //         name: '2023',
            //         y: 200,
            //         drilldown: '2023'
            //     },
            // ],
            // total_fine_drilldown_series:
            //     [
            //         {
            //             name: '2020',
            //             id: '2020',
            //             data: [
            //                 { name: 'country 1', y: 50 },
            //                 { name: 'country 2', y: 10 },
            //                 { name: 'country 3', y: 25 },
            //                 { name: 'country 4', y: 34 },
            //                 { name: 'country 5', y: 56 },
            //                 { name: 'country 6', y: 124 },

            //             ]
            //         },
            //         {
            //             name: '2021',
            //             id: '2021',
            //             data: [
            //                 { name: 'country 1', y: 30 },
            //                 { name: 'country 2', y: 60 },
            //                 { name: 'country 3', y: 25 },
            //                 { name: 'country 4', y: 70 },

            //             ]
            //         },
            //         {
            //             name: '2022',
            //             id: '2022',
            //             data: [
            //                 { name: 'country 1', y: 30 },
            //                 { name: 'country 2', y: 40 },
            //                 { name: 'country 3', y: 25 },
            //                 { name: 'country 4', y: 34 },
            //                 { name: 'country 5', y: 56 },
            //                 { name: 'country 6', y: 20 },
            //             ]
            //         },
            //         {
            //             name: '2023',
            //             id: '2023',
            //             data: [
            //                 { name: 'country 1', y: 30 },
            //                 { name: 'country 2', y: 10 },
            //                 { name: 'country 3', y: 25 },
            //                 { name: 'country 4', y: 34 },
            //                 { name: 'country 5', y: 56 },
            //                 { name: 'country 6', y: 124 },
            //             ]
            //         },

            //     ],

            //chart
            // chartOptions: {

            //     chart: {
            //         type: 'column',

            //         style: {
            //             fontFamily: "'Poppins', sans-serif"
            //         },
            //         events: {
            //             drillup: function (e) {
            //                 console.log(e)
            //             },
            //             drilldown: function (e) {
            //                 console.log(e)
            //                 console.log(this)
            //                 console.log(temp)
            //                 this.xAxis[0].max = 8
            //                 // this.chartOptions.xAxis.max = e.seriesOptions.data.length
            //             }
            //         },

            //         // scrollablePlotArea: {
            //         //     // minWidth: 50*70,
            //         //     minWidth: 500,
            //         //     // mmaxWidth: "100%",
            //         //     scrollPositionX: 1
            //         // }
            //     },
            //     title: {
            //         align: 'left',
            //         text: 'Sum Of Fines By Year And Country'
            //     },
            //     subtitle: {
            //         align: 'left',
            //         text: 'Click the columns to view countries.'
            //     },
            //     accessibility: {
            //         announceNewData: {
            //             enabled: true
            //         }
            //     },
            //     credits: {
            //         enabled: false
            //     },
            //     // scrollbar: {
            //     //     enabled: true
            //     // },
            //     xAxis: {
            //         type: 'category',
            //         // scrollbar: {
            //         //     enabled: true,
            //         //     showFull: false
            //         // },
            //         // min: 0,
            //         // max: 50
            //         min: 0,
            //         max: 3,
            //         scrollbar: {
            //             enabled: true
            //         },
            //         // tickLength: 0
            //     },

            //     yAxis: {
            //         title: {
            //             text: 'Sum of fines'
            //         }

            //     },
            //     legend: {
            //         enabled: false
            //     },
            //     plotOptions: {
            //         series: {
            //             borderWidth: 0,
            //             dataLabels: {
            //                 enabled: true,
            //                 format: '{point.y:.1f}'
            //             }
            //         }
            //     },

            //     tooltip: {
            //         headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            //         pointFormat: '<span >{point.name}</span>: <b>{point.y:.2f}</b><br/>'//<b>{point.y:.2f}%</b> of total<br/>'
            //     },

            //     series:
            //         [
            //             {
            //                 name: 'Years',
            //                 // colorByPoint: true,
            //                 color: "#E68D56",
            //                 data:
            //                     [
            //                         {
            //                             name: '2020',
            //                             y: 30,
            //                             drilldown: '2020'
            //                         },
            //                         {
            //                             name: '2021',
            //                             y: 50,
            //                             drilldown: '2021'
            //                         },
            //                         {
            //                             name: '2022',
            //                             y: 80,
            //                             drilldown: '2022'
            //                         },
            //                         {
            //                             name: '2023',
            //                             y: 20,
            //                             drilldown: '2023'
            //                         },
            //                     ]
            //                 //this.total_fine_data
            //                 // data: [
            //                 //     {
            //                 //         name: 'Chrome',
            //                 //         y: 63.06,
            //                 //         drilldown: 'Chrome'
            //                 //     },
            //                 //     {
            //                 //         name: 'Safari',
            //                 //         y: 19.84,
            //                 //         drilldown: 'Safari'
            //                 //     },
            //                 //     {
            //                 //         name: 'Firefox',
            //                 //         y: 4.18,
            //                 //         drilldown: 'Firefox'
            //                 //     },
            //                 //     {
            //                 //         name: 'Edge',
            //                 //         y: 4.12,
            //                 //         drilldown: 'Edge'
            //                 //     },
            //                 //     {
            //                 //         name: 'Opera',
            //                 //         y: 2.33,
            //                 //         drilldown: 'Opera'
            //                 //     },
            //                 //     {
            //                 //         name: 'Internet Explorer',
            //                 //         y: 0.45,
            //                 //         drilldown: 'Internet Explorer'
            //                 //     },
            //                 //     {
            //                 //         name: 'Other',
            //                 //         y: 1.582,
            //                 //         drilldown: null
            //                 //     },
            //                 //     { name: 'Other01', y: 1.582, drilldown: null },
            //                 //     { name: 'Other02', y: 1.582, drilldown: null },
            //                 //     { name: 'Other03', y: 1.582, drilldown: null },
            //                 //     { name: 'Other04', y: 1.582, drilldown: null },
            //                 //     { name: 'Other05', y: 1.582, drilldown: null },
            //                 //     { name: 'Othe6r0', y: 1.582, drilldown: null },
            //                 //     { name: 'Other07', y: 1.582, drilldown: null },
            //                 //     { name: 'Other08', y: 1.582, drilldown: null },
            //                 //     { name: 'Other-9', y: 1.582, drilldown: null },
            //                 //     { name: 'Other10', y: 1.582, drilldown: null },
            //                 //     { name: 'Other11', y: 1.582, drilldown: null },
            //                 //     { name: 'Other12', y: 1.582, drilldown: null },
            //                 //     { name: 'Other13', y: 1.582, drilldown: null },
            //                 //     { name: 'Other14', y: 1.582, drilldown: null },
            //                 //     { name: 'Other15', y: 1.582, drilldown: null },
            //                 //     { name: 'Other16', y: 1.582, drilldown: null },
            //                 //     { name: 'Other17', y: 1.582, drilldown: null },
            //                 //     { name: 'Other18', y: 1.582, drilldown: null },
            //                 //     { name: 'Other19', y: 1.582, drilldown: null },
            //                 //     { name: 'Other20', y: 1.582, drilldown: null },
            //                 //     { name: 'Other22', y: 1.582, drilldown: null },
            //                 //     { name: 'Other21', y: 1.582, drilldown: null },
            //                 //     { name: 'Other24', y: 1.582, drilldown: null },
            //                 //     { name: 'Other26', y: 1.582, drilldown: null },
            //                 //     { name: 'Other32', y: 1.582, drilldown: null },
            //                 //     { name: 'Other56', y: 1.582, drilldown: null },
            //                 //     { name: 'Other72', y: 1.582, drilldown: null },
            //                 //     { name: 'Other29', y: 1.582, drilldown: null },
            //                 //     { name: 'Other55', y: 1.582, drilldown: null },
            //                 //     { name: 'Other66', y: 1.582, drilldown: null },
            //                 //     { name: 'Other99', y: 1.582, drilldown: null },
            //                 //     { name: 'Other00', y: 1.582, drilldown: null },
            //                 //     { name: 'Other43', y: 1.582, drilldown: null },
            //                 // ]
            //             }
            //         ],
            //     drilldown: {
            //         breadcrumbs: {
            //             position: {
            //                 align: 'right'
            //             }
            //         },
            //         series: [
            //             {
            //                 name: '2020',
            //                 id: '2020',
            //                 data: [
            //                     { name: 'country 1', y: 50 },
            //                     { name: 'country 2', y: 10 },
            //                     { name: 'country 3', y: 25 },
            //                     { name: 'country 4', y: 34 },
            //                     { name: 'country 5', y: 56 },
            //                     { name: 'country 6', y: 124 },

            //                 ]
            //             },
            //             {
            //                 name: '2021',
            //                 id: '2021',
            //                 data: [
            //                     { name: 'country 1', y: 30 },
            //                     { name: 'country 2', y: 60 },
            //                     { name: 'country 3', y: 25 },
            //                     { name: 'country 4', y: 70 },

            //                 ]
            //             },
            //             {
            //                 name: '2022',
            //                 id: '2022',
            //                 data: [
            //                     // { name: 'v11.0', y: 6.2, drilldown: 'test1' },

            //                     { name: 'country 1', y: 30 },
            //                     { name: 'country 2', y: 40 },
            //                     { name: 'country 3', y: 25 },
            //                     { name: 'country 4', y: 34 },
            //                     { name: 'country 5', y: 56 },
            //                     { name: 'country 6', y: 20 },

            //                     // [
            //                     //     'v11.0',
            //                     //     6.2
            //                     // ],
            //                     // [
            //                     //     'v10.0',
            //                     //     0.29
            //                     // ],
            //                     // [
            //                     //     'v9.0',
            //                     //     0.27
            //                     // ],
            //                     // [
            //                     //     'v8.0',
            //                     //     0.47
            //                     // ]
            //                 ]
            //             },
            //             {
            //                 name: 'test1',
            //                 id: 'test1',
            //                 data: [

            //                     [
            //                         'v11.0',
            //                         6.2
            //                     ],
            //                     [
            //                         'v10.0',
            //                         0.29
            //                     ],
            //                     [
            //                         'v9.0',
            //                         0.27
            //                     ],
            //                     [
            //                         'v8.0',
            //                         0.47
            //                     ]
            //                 ]
            //             },
            //             {
            //                 name: '2023',
            //                 id: '2023',
            //                 data: [
            //                     { name: 'country 1', y: 30 },
            //                     { name: 'country 2', y: 10 },
            //                     { name: 'country 3', y: 25 },
            //                     { name: 'country 4', y: 34 },
            //                     { name: 'country 5', y: 56 },
            //                     { name: 'country 6', y: 124 },

            //                 ]
            //             },


            //         ],//this.total_fine_series
            //         // series: [
            //         //     {
            //         //         name: 'Chrome',
            //         //         id: 'Chrome',
            //         //         data: [
            //         //             [
            //         //                 'v65.0',
            //         //                 0.1
            //         //             ],
            //         //             [
            //         //                 'v64.0',
            //         //                 1.3
            //         //             ],
            //         //             [
            //         //                 'v63.0',
            //         //                 53.02
            //         //             ],
            //         //             [
            //         //                 'v62.0',
            //         //                 1.4
            //         //             ],
            //         //             [
            //         //                 'v61.0',
            //         //                 0.88
            //         //             ],
            //         //             [
            //         //                 'v60.0',
            //         //                 0.56
            //         //             ],
            //         //             [
            //         //                 'v59.0',
            //         //                 0.45
            //         //             ],
            //         //             [
            //         //                 'v58.0',
            //         //                 0.49
            //         //             ],
            //         //             [
            //         //                 'v57.0',
            //         //                 0.32
            //         //             ],
            //         //             [
            //         //                 'v56.0',
            //         //                 0.29
            //         //             ],
            //         //             [
            //         //                 'v55.0',
            //         //                 0.79
            //         //             ],
            //         //             [
            //         //                 'v54.0',
            //         //                 0.18
            //         //             ],
            //         //             [
            //         //                 'v51.0',
            //         //                 0.13
            //         //             ],
            //         //             [
            //         //                 'v49.0',
            //         //                 2.16
            //         //             ],
            //         //             [
            //         //                 'v48.0',
            //         //                 0.13
            //         //             ],
            //         //             [
            //         //                 'v47.0',
            //         //                 0.11
            //         //             ],
            //         //             [
            //         //                 'v43.0',
            //         //                 0.17
            //         //             ],
            //         //             [
            //         //                 'v29.0',
            //         //                 0.26
            //         //             ]
            //         //         ]
            //         //     },
            //         //     {
            //         //         name: 'Firefox',
            //         //         id: 'Firefox',
            //         //         data: [
            //         //             [
            //         //                 'v58.0',
            //         //                 1.02
            //         //             ],
            //         //             [
            //         //                 'v57.0',
            //         //                 7.36
            //         //             ],
            //         //             [
            //         //                 'v56.0',
            //         //                 0.35
            //         //             ],
            //         //             [
            //         //                 'v55.0',
            //         //                 0.11
            //         //             ],
            //         //             [
            //         //                 'v54.0',
            //         //                 0.1
            //         //             ],
            //         //             [
            //         //                 'v52.0',
            //         //                 0.95
            //         //             ],
            //         //             [
            //         //                 'v51.0',
            //         //                 0.15
            //         //             ],
            //         //             [
            //         //                 'v50.0',
            //         //                 0.1
            //         //             ],
            //         //             [
            //         //                 'v48.0',
            //         //                 0.31
            //         //             ],
            //         //             [
            //         //                 'v47.0',
            //         //                 0.12
            //         //             ]
            //         //         ]
            //         //     },
            //         //     {
            //         //         name: 'Internet Explorer',
            //         //         id: 'Internet Explorer',
            //         //         data: [
            //         //             { name: 'v11.0', y: 6.2, drilldown: 'test1' },
            //         //             { name: 'v12.0', y: 8 },
            //         //             { name: 'v13.0', y: 8.2 },
            //         //             { name: 'v14.0', y: 9.2 },
            //         //             // [
            //         //             //     'v11.0',
            //         //             //     6.2
            //         //             // ],
            //         //             // [
            //         //             //     'v10.0',
            //         //             //     0.29
            //         //             // ],
            //         //             // [
            //         //             //     'v9.0',
            //         //             //     0.27
            //         //             // ],
            //         //             // [
            //         //             //     'v8.0',
            //         //             //     0.47
            //         //             // ]
            //         //         ]
            //         //     },
            //         //     {
            //         //         name: 'test1',
            //         //         id: 'test1',
            //         //         data: [

            //         //             [
            //         //                 'v11.0',
            //         //                 6.2
            //         //             ],
            //         //             [
            //         //                 'v10.0',
            //         //                 0.29
            //         //             ],
            //         //             [
            //         //                 'v9.0',
            //         //                 0.27
            //         //             ],
            //         //             [
            //         //                 'v8.0',
            //         //                 0.47
            //         //             ]
            //         //         ]
            //         //     },
            //         //     {
            //         //         name: 'Safari',
            //         //         id: 'Safari',
            //         //         data: [
            //         //             [
            //         //                 'v11.0',
            //         //                 3.39
            //         //             ],
            //         //             [
            //         //                 'v10.1',
            //         //                 0.96
            //         //             ],
            //         //             [
            //         //                 'v10.0',
            //         //                 0.36
            //         //             ],
            //         //             [
            //         //                 'v9.1',
            //         //                 0.54
            //         //             ],
            //         //             [
            //         //                 'v9.0',
            //         //                 0.13
            //         //             ],
            //         //             [
            //         //                 'v5.1',
            //         //                 0.2
            //         //             ]
            //         //         ]
            //         //     },
            //         //     {
            //         //         name: 'Edge',
            //         //         id: 'Edge',
            //         //         data: [
            //         //             [
            //         //                 'v16',
            //         //                 2.6
            //         //             ],
            //         //             [
            //         //                 'v15',
            //         //                 0.92
            //         //             ],
            //         //             [
            //         //                 'v14',
            //         //                 0.4
            //         //             ],
            //         //             [
            //         //                 'v13',
            //         //                 0.1
            //         //             ]
            //         //         ]
            //         //     },
            //         //     {
            //         //         name: 'Opera',
            //         //         id: 'Opera',
            //         //         data: [
            //         //             [
            //         //                 'v50.0',
            //         //                 0.96
            //         //             ],
            //         //             [
            //         //                 'v49.0',
            //         //                 0.82
            //         //             ],
            //         //             [
            //         //                 'v12.1',
            //         //                 0.14
            //         //             ]
            //         //         ]
            //         //     }
            //         // ]
            //     }

            // },

            // pie chart
            pie_chart_data_violation_type: [
                // {
                //     name: 'Non-compliance with general data processing principles',
                //     y: 72.04,
                //     // drilldown: 'Chrome'
                // },
                // {
                //     name: 'Insufficient technical and organisational measures to ensure information security',
                //     y: 50.47,
                //     // drilldown: 'Safari'
                // },
                // {
                //     name: 'Insufficient fulfilment of information obligations',
                //     y: 69.32,
                //     // drilldown: 'Edge'
                // },
                // {
                //     name: 'Insufficient legal basis for data processing',
                //     y: 48.15,
                //     // drilldown: 'Firefox'
                // },
                // {
                //     name: 'Insufficient cooperation with supervisory authority',
                //     y: 34.02,
                //     // drilldown: null
                // }
            ],
            pie_chart_data_industry: [
                // {
                //     name: 'Industry and Commerce',
                //     y: 40000
                // },
                // {
                //     name: 'Accomodation and Hospitalty',
                //     y: 10000
                // },
                // {
                //     name: 'Individuals and Private Associations',
                //     y: 2000
                // },
                // {
                //     name: 'Public Sector and Education',
                //     y: 50000
                // },
                // {
                //     name: 'Finance, Insurance and Consulting',
                //     y: 25000,
                // },
                // {
                //     name: 'Media, Telecoms and Broadcasting',
                //     y: 80000
                // },
                // {
                //     name: 'Employment',
                //     y: 10000
                // },
                // {
                //     name: 'Health Care',
                //     y: 29000
                // },
                // {
                //     name: 'Real Estate',
                //     y: 43000
                // },
                // {
                //     name: 'Transportation and Energy',
                //     y: 30000
                // },
            ]
        }
    },
    mounted() {
        console.log(this.countries_list)
        // console.log([1, 2, 3, 4])
        this.countries_list = europe_countries.filter(function (item) {
            return item.continent == "Europe";
        })
        this.countries_list.unshift({ 'country': 'All' })
        console.log(this.countries_list)

        this.industry_list = [
            { name: 'All' },
            { name: 'Accomodation and Hospitalty' },
            { name: 'Employment' },
            { name: 'Finance, Insurance and Consulting' },
            { name: "Health Care" },
            { name: "Individuals and Private Associations" },
            { name: "Industry and Commerce" },
            { name: "Media, Telecoms and Broadcasting" },
            { name: 'Not assigned' },
            { name: 'Public Sector and Education' },
            { name: "Real Estate" },
            { name: 'Transportation and Energy' },
        ]

        this.violation_type_list = [
            { name: 'All' },
            { name: 'Insufficient cooperation with supervisory authority' },
            { name: 'Insufficient data processing agreement' },
            { name: 'Insufficient fulfilment of data breach notification obligations' },
            { name: 'Insufficient fulfilment of data subjects rights' },
            { name: 'Insufficient fulfilment of information obligations' },
            { name: 'Insufficient involvement of data protection officer' },
            { name: 'Insufficient legal basis for data processing' },
            { name: 'Insufficient technical and organisational measures to ensure information security' },
            { name: 'Non-compliance with general data processing principles' },
            { name: 'Unknown' }
        ]

        this.country = ['All']
        this.industry = ['All']
        this.violation_type = ['All']
        let current_date = new Date()
        if (current_date.getMonth() == 0) {
            current_date.setFullYear(current_date.getFullYear() - 1)
        } else {
            current_date.setDate(1)
            current_date.setMonth(0)
        }
        console.log(current_date)
        this.date_from_str = moment(current_date).format('DD-MM-YYYY')
        this.date_from = current_date
        this.date_to_str = moment(new Date()).format('DD-MM-YYYY')
        this.date_to = new Date()
        this.clickFilter()

    },
    watch: {
        // country(newval, oldval) {
        //     // console.log(reactive(newval))
        //     // console.log(JSON.parse(JSON.stringify(newval)))
        //     if (newval.includes('All')) {
        //         this.country = ['All']
        //     }
        // },
        // industry(newval, oldval) {
        //     // console.log(newval)
        //     if (newval.includes('All')) {
        //         this.industry = ['All']
        //     }
        // },
        // violation_type(newval, oldval) {
        //     // console.log(newval)
        //     if (newval.includes('All')) {
        //         this.violation_type = ['All']
        //     }
        // },
        country: {
            handler(newval, oldVal) {
                console.log(newval)
                if (newval.includes('All')) {
                    this.country = 'All' //['All']
                }

            },
            deep: true
        },
        industry: {
            handler(newval, oldVal) {
                console.log(newval)

                if (newval.includes('All')) {
                    this.industry = 'All' //['All']
                }
            },
            deep: true
        },
        violation_type: {
            handler(newval, oldVal) {
                console.log(newval)

                if (newval.includes('All')) {
                    this.violation_type = 'All' //['All']
                }
            },
            deep: true
        },
        date_from(newval) {
            console.log(newval)
            // console.log(new Date(newval))
            // console.log(moment(newval).format('DD-MM-YYYY'))
            this.date_from_str = moment(newval).format('DD-MM-YYYY')
            if (this.date_to != null) {
                if (new Date(this.date_from) > new Date(this.date_to)) {
                    this.invalid_date_from_msg = "The from date can't be greater than to date"
                    this.invalid_date_from = true
                } else {
                    this.invalid_date_from_msg = ''
                    this.invalid_date_from = false
                    this.invalid_date_to_msg = ''
                    this.invalid_date_to = false
                }
            }
        },

        date_to(newval) {
            console.log(newval)
            console.log(new Date(newval))
            this.date_to_str = moment(newval).format('DD-MM-YYYY')

            if (this.date_from != null) {
                if (new Date(this.date_from) > new Date(this.date_to)) {
                    this.invalid_date_to_msg = "The to date can't be less than from date"
                    this.invalid_date_to = true
                } else {
                    this.invalid_date_to_msg = ''
                    this.invalid_date_to = false
                    this.invalid_date_from_msg = ''
                    this.invalid_date_from = false
                }
            }
        },
    },
    methods: {
        changeDate(val) {
            console.log(val)
        },
        clickFilter() {
            console.log("in filter")
            console.log(this.country)
            console.log(this.industry)
            console.log(this.violation_type)
            console.log(this.date_from_str, this.date_to_str)
            this.show_overlay = true

            if (this.total_fine_data.length > 0 && this.is_drilldown) {
                console.log(this.$refs.barChartComponent.$refs.barChart.chart)
                // this.$refs.barChartComponent.$refs.barChart.chart.DrillupCallbackFunction()
            }

            if (this.date_from_str == null || this.date_from_str == '') {
                this.invalid_date_from_msg = "Please enter from date"
                this.invalid_date_from = true
            } else {
                this.invalid_date_from_msg = ''
                this.invalid_date_from = false
            }
            if (this.date_to_str == null || this.date_to_str == '') {
                this.invalid_date_to_msg = "Please enter to date"
                this.invalid_date_to = true
            } else {
                this.invalid_date_to_msg = ''
                this.invalid_date_to = false
            }
            if (this.date_from != null && this.date_from != '' && this.date_to != null && this.date_to != '') {
                if (new Date(this.date_from) > new Date(this.date_to)) {
                    this.invalid_date_to_msg = "The to date can't be less than from date"
                    this.invalid_date_to = true
                } else {
                    this.invalid_date_to_msg = ''
                    this.invalid_date_to = false
                    this.invalid_date_from_msg = ''
                    this.invalid_date_from = false
                }
            }

            if (this.country == null || this.country == '' || this.country.length == 0) {
                this.invalid_country = true
            } else {
                this.invalid_country = false
            }
            if (this.industry == null || this.industry == '' || this.industry.length == 0) {
                this.invalid_industry = true
            } else {
                this.invalid_industry = false
            }
            if (this.violation_type == null || this.violation_type == '' || this.violation_type.length == 0) {
                this.invalid_violation_type = true
            } else {
                this.invalid_violation_type = false
            }

            if (!this.invalid_country && !this.invalid_industry && !this.invalid_violation_type
                && !this.invalid_date_from && !this.invalid_date_to) {
                console.log("valid, before send request")

                let country_uppercase = []
                if (this.country != 'All') {
                    for (let i = 0; i < this.country.length; i++) {
                        country_uppercase.push(this.country[i].toUpperCase())
                    }
                }

                let violation_type_arr = JSON.parse(JSON.stringify(this.violation_type))
                let industry_arr = JSON.parse(JSON.stringify(this.industry))

                let requestData = {
                    "country": this.country == 'All' ? "all" : country_uppercase,//JSON.parse(JSON.stringify(this.country)),
                    "violation_type": violation_type_arr.includes("All") ? "all" : violation_type_arr,
                    "sector": industry_arr.includes("All") ? "all" : industry_arr,
                    "start_date": this.date_from_str,
                    "end_date": this.date_to_str,
                    // "min_fine": 10000,
                    // "max_fine": 2000000,
                }

                console.log(requestData)

                axios
                    .post("https://gdprstats.bumblebeeai.io/gdpr", requestData, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            // client: "CRISK",
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        console.log(response.data);

                        this.total_fine_data = response.data.data_columns
                        this.total_fine_drilldown_series = response.data.drilldown_series
                        this.pie_chart_data_industry = response.data.data_pie_sector
                        this.pie_chart_data_violation_type = response.data.data_pie_violation

                        this.show_overlay = false
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$swal({
                            // title: "Title",
                            text: error,
                            icon: "error",
                            confirmButtonColor: '#03989E',
                            customClass: {
                                confirmButton: "btn btn-primary-protostars"
                            },
                            // timer: 3000,
                        }).then((r) => {
                            // console.log(r.value);
                            this.show_overlay = false
                        });
                    });
            } else {
                this.show_overlay = false
            }
        }
    }

}
</script>
