<template>
    <div>
        <!-- {{ series_data }}
        <br />
        {{ drilldown_data }} -->
        <highcharts class="hc" :options="chartOptions" ref="barChart"></highcharts>
    </div>
</template>


<script >

import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import drilldown from "highcharts/modules/drilldown";
import stockInit from "highcharts/modules/stock";


exportingInit(Highcharts)
drilldown(Highcharts)
stockInit(Highcharts)

Highcharts.setOptions({
    accessibility: {
        keyboardNavigation: {
            enabled: true,
        },
    },
});

export default {
    name: 'BarDrilldownChart',
    components: {
    },
    props: ["title", "subtitle", "yAxis_title", "series_name",
        "series_data", "drilldown_data", "colors", "is_drilldown"],
    data() {
        let temp = this;
        return {
            //chart
            chartOptions: {

                chart: {
                    type: 'column',

                    style: {
                        fontFamily: "'Poppins', sans-serif"
                    },
                    events: {
                        load: function () {
                            // var chart = this
                            // //     barsLength = chart.series[0].data.length;
                            // // console.log("---bbbbbbbb", barsLength)


                            // chart.update({
                            //     chart: {
                            //         height: 400
                            //     }
                            // }, true, false, false);
                        },
                        drillup: function (e) {
                            console.log(e)
                            console.log(temp)
                            var chart = this
                            console.log(temp.series_data.length)
                            chart.update({
                                xAxis: {
                                    max: temp.series_data.length - 1
                                }
                            }, true, false, false);

                            temp._is_drilldown = false
                        },
                        drilldown: function (e) {
                            console.log(e)
                            // console.log(e.seriesOptions.data)
                            // console.log(e.seriesOptions.data.length - 1)
                            console.log(temp)
                            console.log(temp.chartOptions.xAxis.max)
                            console.log(this)
                            var chart = this,
                                barsLength = e.seriesOptions.data.length > 10 ? 9 : e.seriesOptions.data.length - 1;//chart.series[0].data.length;
                            console.log("bbbbbbbb", barsLength)
                            console.log(chart)

                            chart.update({
                                xAxis: {
                                    max: barsLength
                                }
                            }, true, false, false);

                            temp._is_drilldown = true
                            // temp.chartOptions.xAxis.max = e.seriesOptions.data.length-1
                            // this.xAxis.max = e.seriesOptions.data.length;
                            // temp.chartOptions.xAxis.max = 20;
                            // console.log(temp.chartOptions.xAxis.max)
                            // this.xAxis[0].min = 0
                            // this.chartOptions.xAxis.max = e.seriesOptions.data.length-1
                            // temp.chartOptions.xAxis.max = e.seriesOptions.data.length
                            // temp.chartOptions.chart.scrollablePlotArea.minWidth = 20*(e.seriesOptions.data.length-1)
                        }
                    },

                    scrollablePlotArea: {
                        // minWidth: 50*70,
                        minWidth: 300,
                        maxWidth: "100%",
                        scrollPositionX: 1
                    },

                    height: 500,
                },
                title: {
                    align: 'left',
                    text: this.title
                },
                subtitle: {
                    align: 'left',
                    text: this.subtitle
                },
                accessibility: {
                    announceNewData: {
                        enabled: true
                    }
                },
                credits: {
                    enabled: false
                },
                // scrollbar: {
                //     enabled: true
                // },
                xAxis: {
                    type: 'category',
                    // scrollbar: {
                    //     enabled: true,
                    //     showFull: false
                    // },
                    // min: 0,
                    // max: 50
                    min: 0,
                    max: this.series_data.length - 1,
                    // scrollbar: {
                    //     enabled: true
                    // },
                    // tickLength: 0
                },

                scrollbar: {
                    enabled: true,
                    max: this.series_data.length - 1
                },
                yAxis: {
                    title: {
                        text: this.yAxis_title
                    }

                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            // format: '{point.y:.1f}',
                            formatter: function () {
                                // return Highcharts.numberFormat(this.y, {
                                //     thousandsSeparator: "*"
                                // });
                                return convert(this.y)//this.y.toLocaleString()
                            }
                        }
                    }
                },

                tooltip: {
                    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    pointFormat: '<span >{point.name}</span>: <b>{point.y:.2f}</b><br/>',//<b>{point.y:.2f}%</b> of total<br/>'
                    formatter: function () {
                        return Highcharts.numberFormat(this.y, {
                            thousandsSeparator: ","
                        });
                    }
                },

                series:
                    [
                        {
                            name: this.series_name,
                            // colorByPoint: true,
                            color: this.colors[0], //"#E68D56",
                            data: this.series_data
                        }
                    ],
                drilldown: {
                    breadcrumbs: {
                        position: {
                            align: 'right'
                        },
                        buttonTheme: {
                            style: {
                                color: '#e68d56'
                            }
                        },
                        separator: {
                            fontSize: 16
                        }
                    },
                    series: this.drilldown_data,
                    activeAxisLabelStyle: {
                        color: '#0C161B'
                    },
                    activeDataLabelStyle: {
                        color: '#0C161B'
                    }
                }


            },
        }
    },
    mounted() {
    },
    computed: {
        _is_drilldown: {
            get() {
                return this.is_drilldown;
            },
            set(value) {
                this.$emit("update:is_drilldown", value);
            },
        },
    },
    watch: {
        // series_data(newval) {

        // },
        series_data: {
            handler(newval, oldVal) {
                console.log(newval)
                console.log(newval.length)
                if (this.is_drilldown) {
                    this.$refs.barChart.chart.drillUp();
                }

                this.chartOptions.series[0].data = JSON.parse(JSON.stringify(newval))
                // this.chartOptions.xAxis.min = 0
                this.chartOptions.xAxis.max = JSON.parse(JSON.stringify(newval)).length - 1

                console.log(this.$refs.barChart.chart)
                this.$refs.barChart.chart.redraw()
            },
            deep: true
        },

        drilldown_data: {
            handler(newval, oldVal) {
                console.log(newval)
                this.chartOptions.drilldown.series = JSON.parse(JSON.stringify(newval))
                    
            },
            deep: true
        },

        // drilldown_data(newval) {
        //     console.log(newval)
        //     this.chartOptions.drilldown.series = JSON.parse(JSON.stringify(newval))
        //     // this.$refs.barChart.chart.redraw()

        // }
    },
    methods: {

    }


}
function convert(val) {
    // Thousands, millions, billions etc..
    let s = ["", "K", "M", "B", "T"];

    // Dividing the value by 3.
    let sNum = Math.floor(("" + val).length / 3);

    // Calculating the precised value.
    let sVal = parseFloat(
        (sNum != 0
            ? val / Math.pow(1000, sNum)
            : val
        ).toPrecision(2)
    );

    if (sVal % 1 != 0) {
        sVal = sVal.toFixed(1);
    }

    const i = Math.floor(Math.log(val) / Math.log(1000))

    let temp = `${parseFloat((val / Math.pow(1000, i)).toFixed(2))} ${s[i]}`
    // Appending the letter to precised val.
    // console.log(sVal + s[sNum], "----", temp)
    return temp;//sVal + s[sNum];
}
</script>
