// import Vue from 'vue'
import { createRouter, createWebHistory } from "vue-router";
// import HelloWorld from '@/components/HelloWorld'
import StatisticsView from '@/views/StatisticsView.vue'
import ProtostarsStatisticsView from '@/views/ProtostarsStatisticsView.vue'

// Vue.use(Router)

const routes = [
  {
    path: '/crisk',
    name: 'StatisticsView',
    component: StatisticsView
  },
  {
    path: '/protostars',
    name: 'ProtostarsStatisticsView',
    component: ProtostarsStatisticsView
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  },
});
// = new Router({
//   mode: 'history',
//   routes,
//   scrollBehavior: function (to) {
//     if (to.hash) {
//       return {
//         selector: to.hash
//       }
//     }
//   },
// })

export default router
