// import  { createApp } from 'vue'
import Vue, { createApp } from '@vue/compat';

import App from './App.vue'
import router from './router'

import VueAxios from 'vue-axios'
import axios from 'axios'

import { BootstrapVue } from 'bootstrap-vue'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)



// import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/css/styles.css'


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faCheck, faCircleCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { faCopyright } from '@fortawesome/free-regular-svg-icons'
library.add({ faBars, faCheck, faCircleCheck, faCopyright, faEye, faEyeSlash });

import Vue3Marquee from 'vue3-marquee'

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import HighchartsVue from 'highcharts-vue'


axios.defaults.withCredentials = false
// axios.defaults.baseURL = 'http://gdprstats.bumblebeeai.io/'
// axios.defaults.baseURL = 'https://microservices.bumblebeeai.io/api/'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

createApp(App).use(router).use(VueAxios, axios).use(VueSweetalert2).use(Vue3Marquee).component("font-awesome-icon", FontAwesomeIcon).component("v-select", vSelect).use(HighchartsVue).mount('#app')
