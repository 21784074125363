<template>
    <div>
        <b-navbar toggleable="xl" fixed="top" class="navbar-top px-xl-5 px-md-3 px-0" :class="navbar_class">
            <b-navbar-brand href="" class="mx-md-3 mx-1">
                <router-link to="/" class="nav-link d-flex px-0">
                    <img src="@/assets/images/c-risk-logo.png" />
                </router-link>
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse">
                <template #default>
                    <font-awesome-icon icon="fa-solid fa-bars" />
                </template>
            </b-navbar-toggle>

            
        </b-navbar>
    </div>
</template>
<script>
export default {
    name: "NavBar",
    props: [],
    data() {
        return {
        };
    },
    mounted() {
    }
};
</script>