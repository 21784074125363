<template>
    <div>
        <highcharts class="hc" :options="pieChartOptions" ref="piechart"></highcharts>
    </div>
</template>


<script >

import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import drilldown from "highcharts/modules/drilldown";
import stockInit from "highcharts/modules/stock";


exportingInit(Highcharts)
drilldown(Highcharts)
stockInit(Highcharts)

// Highcharts.setOptions({
//             colors: ['#000','#fff','#f00']
//         });
// const colors = Highcharts.getOptions().colors.map((c, i) =>
//     // Start out with a darkened base color (negative brighten), and end
//     // up with a much brighter color
//     Highcharts.color(Highcharts.getOptions().colors[3])
//         .brighten((i - 1) / 20)
//         .get()
// );

export default {
    name: 'PieChart',
    components: {
    },
    props: ["title", "subtitle", "series_name", "series_data", "colors"],
    data() {
        let temp = this;
        return {

            //pie chart
            pieChartOptions: {
                chart: {
                    type: 'pie',
                    style: {
                        fontFamily: "'Poppins', sans-serif"
                    }
                },
                title: {
                    text: this.title,
                    align: 'left'
                },
                subtitle: {
                    // text: 'Click the slices to view versions. ',
                    text: this.subtitle,
                    align: 'left'
                },

                accessibility: {
                    announceNewData: {
                        enabled: true
                    },
                    point: {
                        valueSuffix: '%'
                    }
                },
                credits: {
                    enabled: false
                },

                plotOptions: {
                    series: {
                        borderRadius: 5,
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}: <b>{point.percentage:.1f}%</b>'
                            // format: '<b>{point.name}</b>:<br>{point.percentage:.1f} %<br>value: {point.y}'
                            //https://jsfiddle.net/gk8jotz1/
                            //https://jsfiddle.net/gk8jotz1/1/
                        }
                    },
                    // colors: ['#000','#fff','#f00']
                },
                lang: {
                    thousandsSep: ','
                },

                tooltip: {
                    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    pointFormat: '<span >{point.name}</span>: <b>{point.y:.2f}</b> <br/> <b>{point.percentage:.1f}% </b> of total',
                    formatter: function () {
                        return convert(this.y)
                        // return Highcharts.numberFormat(this.y, {
                        //     thousandsSeparator: ","
                        // });
                    }
                },

                series: [
                    {
                        name: this.series_name,
                        colorByPoint: true,
                        colors: this.colors,
                        data: this.series_data
                    }
                ],
                // drilldown: {
                //     series: [
                //         {
                //             name: 'Chrome',
                //             id: 'Chrome',
                //             data: [
                //                 [
                //                     'v97.0',
                //                     36.89
                //                 ],
                //                 [
                //                     'v96.0',
                //                     18.16
                //                 ],
                //                 [
                //                     'v95.0',
                //                     0.54
                //                 ],
                //                 [
                //                     'v94.0',
                //                     0.7
                //                 ],
                //                 [
                //                     'v93.0',
                //                     0.8
                //                 ],
                //                 [
                //                     'v92.0',
                //                     0.41
                //                 ],
                //                 [
                //                     'v91.0',
                //                     0.31
                //                 ],
                //                 [
                //                     'v90.0',
                //                     0.13
                //                 ],
                //                 [
                //                     'v89.0',
                //                     0.14
                //                 ],
                //                 [
                //                     'v88.0',
                //                     0.1
                //                 ],
                //                 [
                //                     'v87.0',
                //                     0.35
                //                 ],
                //                 [
                //                     'v86.0',
                //                     0.17
                //                 ],
                //                 [
                //                     'v85.0',
                //                     0.18
                //                 ],
                //                 [
                //                     'v84.0',
                //                     0.17
                //                 ],
                //                 [
                //                     'v83.0',
                //                     0.21
                //                 ],
                //                 [
                //                     'v81.0',
                //                     0.1
                //                 ],
                //                 [
                //                     'v80.0',
                //                     0.16
                //                 ],
                //                 [
                //                     'v79.0',
                //                     0.43
                //                 ],
                //                 [
                //                     'v78.0',
                //                     0.11
                //                 ],
                //                 [
                //                     'v76.0',
                //                     0.16
                //                 ],
                //                 [
                //                     'v75.0',
                //                     0.15
                //                 ],
                //                 [
                //                     'v72.0',
                //                     0.14
                //                 ],
                //                 [
                //                     'v70.0',
                //                     0.11
                //                 ],
                //                 [
                //                     'v69.0',
                //                     0.13
                //                 ],
                //                 [
                //                     'v56.0',
                //                     0.12
                //                 ],
                //                 [
                //                     'v49.0',
                //                     0.17
                //                 ]
                //             ]
                //         },
                //         {
                //             name: 'Safari',
                //             id: 'Safari',
                //             data: [
                //                 [
                //                     'v15.3',
                //                     0.1
                //                 ],
                //                 [
                //                     'v15.2',
                //                     2.01
                //                 ],
                //                 [
                //                     'v15.1',
                //                     2.29
                //                 ],
                //                 [
                //                     'v15.0',
                //                     0.49
                //                 ],
                //                 [
                //                     'v14.1',
                //                     2.48
                //                 ],
                //                 [
                //                     'v14.0',
                //                     0.64
                //                 ],
                //                 [
                //                     'v13.1',
                //                     1.17
                //                 ],
                //                 [
                //                     'v13.0',
                //                     0.13
                //                 ],
                //                 [
                //                     'v12.1',
                //                     0.16
                //                 ]
                //             ]
                //         },
                //         {
                //             name: 'Edge',
                //             id: 'Edge',
                //             data: [
                //                 [
                //                     'v97',
                //                     6.62
                //                 ],
                //                 [
                //                     'v96',
                //                     2.55
                //                 ],
                //                 [
                //                     'v95',
                //                     0.15
                //                 ]
                //             ]
                //         },
                //         {
                //             name: 'Firefox',
                //             id: 'Firefox',
                //             data: [
                //                 [
                //                     'v96.0',
                //                     50
                //                 ],
                //                 [
                //                     'v95.0',
                //                     25
                //                 ],
                //                 [
                //                     'v94.0',
                //                     10
                //                 ],
                //                 [
                //                     'v91.0',
                //                     10
                //                 ],
                //                 [
                //                     'v78.0',
                //                     5
                //                 ],
                //                 [
                //                     'v52.0',
                //                     12
                //                 ]
                //             ]
                //         }
                //     ]
                // }
            }
        }
    },
    mounted() {
        console.log(this.colors)
    },
    watch: {
        // series_data(newval) {
        //     console.log(newval)
        //     this.pieChartOptions.series[0].data = newval
        // }
        
        series_data: {
            handler(newval, oldVal) {
                console.log(newval)
                this.pieChartOptions.series[0].data = newval
                    
            },
            deep: true
        },
    },
    methods: {

    }


}

function convert(val) {
    // Thousands, millions, billions etc..
    let s = ["", "K", "M", "B", "T"];

    // Dividing the value by 3.
    let sNum = Math.floor(("" + val).length / 3);

    // Calculating the precised value.
    let sVal = parseFloat(
        (sNum != 0
            ? val / Math.pow(1000, sNum)
            : val
        ).toPrecision(2)
    );

    if (sVal % 1 != 0) {
        sVal = sVal.toFixed(1);
    }

    const i = Math.floor(Math.log(val) / Math.log(1000))

    let temp = `${parseFloat((val / Math.pow(1000, i)).toFixed(2))} ${s[i]}`
    // Appending the letter to precised val.
    // console.log(sVal + s[sNum], "----", temp)
    return temp;//sVal + s[sNum];
}
</script>
