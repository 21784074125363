<template>
  <div>
    <Navbar v-if="website_domain=='crisk'"/>
    <ProtostarsNavbar v-if="website_domain=='protostars'"/>
    <div class="home-page">

      <div class="content">
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar.vue';
import ProtostarsNavbar from '@/components/layout/ProtostarsNavbar.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    ProtostarsNavbar
  },
  data() {
    return {
      website_uri : '',
      website_domain : ''
    };
  },
  mounted() {
    this.website_uri = window.location;
    if(this.website_uri.href.indexOf('protostars.ai')!==-1 || this.website_uri.href.indexOf('/protostars')!==-1){
      this.website_domain = 'protostars';
    } else if(this.website_uri.href.indexOf('c-risk.com')!==-1 
      || this.website_uri.href.indexOf('crisk.bumblebeeai.io')!==-1
      || this.website_uri.href.indexOf('/crisk')!==-1){
      this.website_domain = 'crisk';
    }
    console.log('WEBSITE DOMAIN: '+this.website_domain);
  }
}
</script>

<style></style>
